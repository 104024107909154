import React from 'react';
import styled from 'styled-components';
import {
  Color,
  h2Desktop,
  h2Mobile, height,
  marginLeft,
  marginTop, maxWidth, owlSelector,
  typographyByBreakpoint,
  xxlDesktop,
  xxlMobile,
  bodyDefault,
  h3Desktop,
  typography, Width,
  gridColumns, width, flex, paddingBottom
} from "../../../styles";
import { Background } from "../../../pages/mission";
// @ts-ignore
import Image from '../../../images/contact/hero-background.png';

const Wrapper =  styled.section`
  position: relative;
  margin-inline: auto;
  ${maxWidth('32.7rem', '68.8rem', '120rem')};
  ${paddingBottom(80, 100,152)};
  
  .divider {
    ${width('100%', 482,1)};
    ${height(1, 1,184)};
    background-color: ${Color.OFF_WHITE};
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    .border{
      border-right: 1px solid white;
    }
    .divider{
      display: none;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const HeroContent = styled.div`
  padding-top: 166px;
  
  h1 {
    ${typographyByBreakpoint(xxlMobile, xxlMobile, xxlDesktop)};
    color: ${Color.SEAFOAM_GREEN};
  }
  p{
    ${marginLeft(0,30,152)};
    margin-top: 16px;
    ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
    color: ${Color.OFF_WHITE};
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    h1{
      ${typography('16rem', 'calc(339 / 274)', '0.5rem')};
    }
  }
`;

const CardContainer = styled.div`
  ${marginTop(48,96)};
  ${marginLeft(0, 26)};
  display: grid;
  ${gridColumns(1, 1, 3)}
  ${owlSelector('top', 40,40,0)};
`;

const Card = styled.div`
  h2{
    ${typographyByBreakpoint(bodyDefault, bodyDefault, bodyDefault)};
    margin-bottom: 8px;
    color: ${Color.OFF_WHITE};
  }
  a{
    display: block;
    ${typographyByBreakpoint(h3Desktop, h3Desktop, h3Desktop)};
    color: ${Color.GREEN};
  }

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    padding-left: 7rem;
    ${flex('center', 'flex-start', 'column')};
    height: 18.4rem;
  }
` ;

const Hero = () => (
  <Wrapper>
    <Background />
    <Content>
      <HeroContent>
        <h1>Contact</h1>
        <p>Get in touch with us</p>
      </HeroContent>
      <CardContainer>
        <Card className={'border'}>
          <h2> General Questions</h2>
          <a href="mailto:support@readylife.com">support@readylife.com</a>
        </Card >
        <div className={'divider'} />
        <Card className={'border'}>
          <h2> Media Requests</h2>
          <a href="mailto:media@readylife.com">media@readylife.com</a>
        </Card>
        <div className={'divider'} />
        <Card>
          <h2> Investor</h2>
          <a href="mailto:investors@readylife.com">investors@readylife.com</a>
        </Card>
      </CardContainer>
    </Content>
  </Wrapper>
)

export default Hero
