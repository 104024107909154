import React from 'react';
import WebsiteDefaultPage from '../components/Page/Base';
import { Hero } from "../components/PageSpecific/contact";
import { JoinSection } from "../components/SharedSections";
import {Color} from "../styles";
import {LIGHT_DARK} from "../constants";

const Contact = () => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.DARK_GRAY} pageState={{ theme: LIGHT_DARK.DARK }}>
      <Hero />
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export default Contact;
